import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import PageHead from "@Components/PageHead";
import { Case } from "@Components/Home/CaseStudies";
import "../styles/work.scss";

function SecondPage() {
  const data = useStaticQuery(PageQuery);
  return (
    <>
      <PageHead backgroundImg={data.workHero.childImageSharp.fluid}>
        <h2 className="head-title">
          We take <span>proud</span> in our work
        </h2>
      </PageHead>
      <section className="work container">
        <Case
          title="SLPS"
          subTitle="React Native Application for Solgam Community"
          caseLink="/projects/slps"
          imageSource={data.slpsBanner.childImageSharp.fluid}
        />
        <div className="work__footer">
          <h2>And many more to come</h2>
        </div>
      </section>
    </>
  );
}

export default SecondPage;

export const PageQuery = graphql`
  query {
    workHero: file(relativePath: { eq: "frontendHero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slpsBanner: file(relativePath: { eq: "projects/slps/SLPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
