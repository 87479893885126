import React, { useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import useScreenObserver from "@Utils/Observer";

import "./styles/_caseStudies.scss";

export interface CaseProps {
  title: string;
  subTitle: string;
  caseLink: string;
  imageSource: any;
}

export const Case = ({ title, subTitle, caseLink, imageSource }: CaseProps) => (
  <div className="case-container">
    <div className="case-container-info">
      <h2 className="case-head">{title}</h2>
      <p className="case-info">{subTitle}</p>
      <Link className="case-link" to={caseLink}>
        Read More <span>🡪</span>
      </Link>
    </div>
    <div className="case-container-image">
      <Img
        fluid={imageSource}
        alt={title}
        imgStyle={{ transition: "all 0.25s ease-in" }}
      />
    </div>
  </div>
);

function CaseStudies() {
  const [visible, setRef] = useScreenObserver({ rootMargin: "-100px" });

  const data = useStaticQuery(PageQuery);

  useEffect(() => {
    if (visible && setRef.current) {
      setRef.current.classList.add("heading-animation");
    }
  }, [setRef, visible]);

  return (
    <div className="container services caseStudies">
      <div className="services__head">
        <div className="caseStudies__heading">
          <h2 ref={setRef} className="section-heading">
            What we have
            <span className="sub-head"> learned </span>!
          </h2>
          <p className="caseStudies__heading--sub">
            When working on projects we stumble upon various problems and
            challenges which we live to solve them, here are some of our case
            studies in which we explain what kind of problems we faced and how
            we solved them.
          </p>
        </div>
        <div className="services__tag-right">Case Studies</div>
      </div>
      <Case
        title="SLPS"
        subTitle="React Native Application for Solgam Community"
        caseLink="/projects/slps"
        imageSource={data.slpsBanner.childImageSharp.fluid}
      />
      <div className="case-readmore case-link">
        <Link to="/work">Check Out More 🡪</Link>
      </div>
    </div>
  );
}

export default CaseStudies;

export const PageQuery = graphql`
  query {
    slpsBanner: file(relativePath: { eq: "projects/slps/SLPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
